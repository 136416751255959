import '../../App.css'
import { signal } from "@preact/signals-react";
export const eventId = signal("");
function Lottery() {
   
    return (
        <>
            {/* Desktop */}

            <div className="hidden lg:grid grid-cols-12 gap-x-6 bg-[url('/public/other/casinobg.webp')] bg-contain bg-fixed p-[0.938rem] ">
                <div className='col-span-3'>
                    <div className='relative h-[16vh]'>
                        <div className='clip'></div>
                        <div className='clip2'>
                            <img title="" alt="" className="w-full h-full" src='/other/lottery.webp' />
                        </div>
                    </div>
                    <div className='w-full text-center text-[0.813rem] font-semibold uppercase [text-shadow:0px_4px_4px_rgba(0,0,0,.35)]'>
                        Lottery
                    </div>
                </div>
            </div>
            {/* Mobile */}
            <div className="lg:hidden grid grid-cols-12 gap-x-4 bg-[url('/public/other/casinobg.webp')] bg-contain bg-fixed p-[0.938rem] border-b-[1px] border-[#7e97a7]">
                <div className='col-span-4'>
                    <div className='relative h-[115px]'>
                        <div className='clip w-[100%] !h-[115px]'></div>
                        <div className='clip2 !w-[89%] !h-[98px] !bottom-[100px] !left-[0vh]'>
                            <img title="" alt="" className="w-full h-full" src='/other/lottery.webp'></img>
                        </div>
                    </div>
                    <div className='w-full text-center text-[0.813rem] font-semibold uppercase [text-shadow:0px_4px_4px_rgba(0,0,0,.35)]'>
                        Lottery
                    </div>
                </div>
            </div>
        </>
    );
}

export default Lottery;
