import '../../App.css'
import { useState } from 'react';
import { TfiPencilAlt } from "react-icons/tfi";
import RollingCommission from '../../components/rollingCommission';
import { Modal, Input } from 'antd';
import Appconfig from '../../config/config'
import { useNavigate } from 'react-router-dom';
import {  NotificationManager } from 'react-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { emptyUserInfo } from '../../redux/slice/userInfo/userInfoSlice';


import axios from 'axios';
const MyProfile = () => {
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state?.userInfo?.userdata?.value); // get login user details
    const dispatch = useDispatch();

    const [openEdit, setOpenEdit] = useState(false)
    const [openRollingCommission, setOpenRollingCommission] = useState(false)
    const [user_nameValidationError, setUser_NameValidationError] = useState("Please enter old password");
    const [passwordValidationError, setPasswordValidationError] = useState("Please enter new password");
    const [conpasswordValidationError, setConPasswordValidationError] = useState("Please check your confirm password");

    const [button, setButton] = useState(false)

    // console.log('userInfo', userInfo);
    const [formData, setformData] = useState({
        user_id: userInfo?._id,
        name: userInfo?.name,
        user_name: userInfo?.user_name,
        user_type: userInfo?.user_type,
        old_password: '',
        new_password: '',
        con_password: '',

    });
    function checkValidation() {

        if (formData.old_password.trim() === "") {

            setUser_NameValidationError('Please enter old password')
            document.querySelector('.oldpassword-error').classList.remove('hidden');
            // console.log('hlw');

        }
        if (formData.new_password.trim() === "") {
            setPasswordValidationError('Please enter new password')
            document.querySelector('.newpassword-error').classList.remove('hidden');
        }
        if (formData.con_password.trim() === formData.new_password.trim()) {
            document.querySelector('.conpassword-error').classList.remove('hidden');
            setConPasswordValidationError('Please check your confirm password')
        }

        if (formData.old_password !== "" && formData.new_password !== "" && formData.con_password !== "" && formData.new_password === formData.con_password) {
            return true
        }
        return false;

    }

    function setOldPassword(value) {
        setformData({
            ...formData,
            old_password: value
        })
    }

    function setNewPassword(value) {
        setformData({
            ...formData,
            new_password: value
        })
    }

    function setConPassword(value) {
        setformData({
            ...formData,
            con_password: value
        })
    }

    const changePassword = async () => {

        if (checkValidation()) {
            formData.master_id = userInfo?.master_id;
            formData.masters = userInfo?.masters;

            setButton(true);
            // console.log('formData', formData);
            // return false;
            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}users/auraChangePassword`,
                headers: {
                    'Content-Type': 'application/json',

                },

                data: JSON.stringify(formData)

            };
            axios(config)
                .then(function (response) {

                    if (response.data.result) {
                        NotificationManager.success(response.data.message, '', 3000);
                        setOpenEdit(false);
                        setButton(false);

                        formData.old_password = '';
                        formData.new_password = '';
                        formData.con_password = '';

                        setTimeout(() => {
                            localStorage.removeItem('userdata');
                            localStorage.removeItem('login_token');
                            dispatch(emptyUserInfo());

                            navigate('/');
                        }, 3000)

                    } else {
                        NotificationManager.error(response.data.message, '', 3000);

                    }


                })
                .catch(function (error) {
                    console.log(error);
                });

        }


    }
    return (
        <>
            {/* Desktop */}
            <div className='hidden lg:block w-full'>
                <div className='mt-[22.5px] mb-[40px] p-[.15rem]'>
                    <div className='flex flex-wrap mt-[calc(-1*0)] mr-[calc(-.5*1.5rem)] ml-[calc(-.5*1.5rem)]'>
                        <div className='pl-[9.5px] pr-[10px] flex-[0_0_auto] w-full mt-0'>
                            <div className='relative flex flex-col min-w-[0] text-[#212529] [word-wrap:break-word] bg-[#fff] bg-clip-border border-[1px]  border-[#0000002d] rounded-[.375rem]'>
                                <div className='px-[6px] py-[5px] font-bold text-[0.875rem] !rounded-t-[3px] h-[35px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff]'>
                                    <span className='relative top-[2px] font-bold text-[0.875rem] text-[#ffffff]'>Account Details</span>
                                </div>
                                <div className='px-[11px] py-[5px] flex-auto'>
                                    <div >
                                        <dl className='mt-0 mb-4 flex flex-wrap mr-[calc(-.5*1.5rem)] ml-[calc(-.5*1.5rem)]'>
                                            <dt className='pl-[16px] pb-[3px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[25%] font-bold'>Name</dt>
                                            <dd className='pl-[16px] pb-[3px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[75%]'>{userInfo?.user_name}</dd>
                                            <dt className='pl-[16px] pb-[3px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[25%] font-bold'>Commission</dt>
                                            <dd className='pl-[16px] pb-[3px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[75%]'> {userInfo?.commission} </dd>
                                            <dt className='pl-[16px] pb-[3px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[25%] font-bold'>Rolling Commission</dt>
                                            <dd className='pl-[16px] pb-[3px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[75%]'>
                                                <button className='fill-[balck] hover:fill-[#09437f]' onClick={() => setOpenRollingCommission(true)}>
                                                    <svg width="16" height="12" viewBox="0 0 16 10" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8 0C1.3 0 0 5.1 0 5.1C0 5.1 2.2 9.2 7.9 9.2C13.6 9.2 16 5.2 16 5.2C16 5.2 14.7 0 8 0ZM5.3 1.5C5.8 1.2 6.6 1.2 6.6 1.2C6.6 1.2 6.1 2.1 6.1 2.8C6.1 3.5 6.3 3.9 6.3 3.9L5.2 4.1C5.2 4.1 4.9 3.6 4.9 2.9C4.9 2.1 5.3 1.5 5.3 1.5ZM7.9 8.2C3.8 8.2 1.7 5.9 1.1 5C1.4 4.3 2.2 2.8 4.2 1.8C4.1 2.2 4 2.6 4 3.1C4 5.3 5.8 7.1 8 7.1C10.2 7.1 12 5.3 12 3.1C12 2.6 11.9 2.2 11.8 1.8C13.8 2.7 14.6 4.3 14.9 5C14.2 5.9 12.1 8.2 7.9 8.2Z" />
                                                    </svg>
                                                </button>
                                                <Modal title={'Rolling Commission'} className='rollingcommission-model relative top-1 lg:top-7 lg:!w-[34vw]' mask={false} onCancel={() => setOpenRollingCommission(false)} footer={null} open={openRollingCommission} >
                                                    <RollingCommission />
                                                </Modal>
                                            </dd>
                                            <dt className='pl-[16px] pb-[3px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[25%] font-bold'>Exposure Limit</dt>
                                            <dd className='pl-[16px] pb-[3px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[75%]'> {userInfo?.exposer_limit} </dd>
                                            <dt className='pl-[16px] pb-[3px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[25%] font-bold'>Password</dt>
                                            <dd className='pl-[16px] pb-[3px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[75%]'>
                                                <span className='mr-4'>*********</span>
                                                <button className='!text-[#000]' onClick={() => setOpenEdit(true)}>
                                                    <span className='flex items-center gap-1 text-[black] hover:text-[#09437f]'>Edit <TfiPencilAlt /></span>
                                                </button>
                                                <Modal className='password-model relative top-1 lg:top-7 lg:!w-[34vw]'
                                                    footer={[
                                                        <div className='col-span-12 gap-2 flex flex-shrink-0 flex-wrap items-center justify-end p-[1rem] border-t-[1px] border-t-[#dee2e6]'>
                                                            <button type='submit ' disabled={button} className='text-white bg-[#1c272d] hover:!bg-transparent hover:text-inherit border-[1px] border-[#1c272d]   text-[.875rem] rounded-[.25rem] px-[10px] py-[5px]   '
                                                                onClick={() => changePassword()}
                                                            >
                                                                Confirm
                                                            </button>
                                                            <button className='text-[#23282c] bg-[#c8ced3] hover:!bg-transparent hover:text-inherit border-[#c8ced3] text-[.875rem] rounded-[.25rem] inline-block px-[10px] py-[5px] font-normal leading-normal text-center no-underline align-middle border-[1px] border-[solid] border-[#6c757d] [transition:color_.15s_ease-in-out,_background-color_.15s_ease-in-out,_border-color_.15s_ease-in-out,_box-shadow_.15s_ease-in-out]'
                                                                onClick={() => setOpenEdit(false)}
                                                            >
                                                                No
                                                            </button>
                                                        </div>
                                                    ]}
                                                    mask={false} open={openEdit} >
                                                    <div className='grid grid-cols-12'>
                                                        <div className='col-span-12 px-[6px] py-[5px] font-bold text-[0.875rem] rounded-t-[5px] h-[35px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex flex-shrink-0 items-center justify-between'>
                                                            <h5 className='text-[0.938rem] font-bold mb-0 leading-normal'>Change Password</h5>
                                                            <button type="button" onClick={() => setOpenEdit(false)} >
                                                                <span className='text-[1.3125rem] font-bold'>×</span>
                                                            </button>
                                                        </div>
                                                        <div className='col-span-12 !bg-[#ffffff] !text-[#23282c] relative flex-auto p-4'>
                                                            <div className='grid grid-cols-12 lg:gap-x-6 '>
                                                                <div className='col-span-12 lg:col-span-6'>
                                                                    <div className='mb-4 !text-[#23282c]'>
                                                                        <label className='inline-block mb-[.5rem] !text-[#23282c]' for="oldPassword">Old Password </label>
                                                                        <div className='relative flex flex-wrap items-stretch w-full text-[#23282c]'>
                                                                            <Input.Password id='oldPassword' name='oldPassword'
                                                                                onChange={(e) => setOldPassword(e.target.value)}
                                                                                required className='antd-password' placeholder="Old Password.." />
                                                                            <span className="absolute top-[25px] oldpassword-error hidden  text-[0.813rem] text-[red] leading-[14px]">
                                                                                <div className='leading-[14px] '>{user_nameValidationError}</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-span-12 lg:col-span-6'>
                                                                    <div className='mb-4 !text-[#23282c]'>
                                                                        <label className='inline-block mb-[.5rem] !text-[#23282c]' for="newPassword">New Password  </label>
                                                                        <div className='relative flex flex-wrap items-stretch w-full text-[#23282c]'>
                                                                            <Input.Password id='newPassword' name='newPassword'
                                                                                onChange={(e) => setNewPassword(e.target.value)} required className='antd-password' pattern='^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&]{8,}$' placeholder="New Password.." />
                                                                            <span className="absolute top-[25px] newpassword-error hidden  text-[0.813rem] text-[red] leading-[14px]">
                                                                                <div className='leading-[14px] '>{passwordValidationError}</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-span-12 lg:col-span-6'>
                                                                    <div className='mb-4 !text-[#23282c]'>
                                                                        <label className='inline-block mb-[.5rem] !text-[#23282c]' for="confirmPass">Confirm Password  </label>
                                                                        <div className='relative flex flex-wrap items-stretch w-full text-[#23282c]'>
                                                                            <Input.Password id='confirmPass' name='confirmPass'
                                                                                onChange={(e) => setConPassword(e.target.value)} required className='antd-password' placeholder="Confirm Password.." />
                                                                            <span className="absolute top-[25px] conpassword-error hidden  text-[0.813rem] text-[red] leading-[14px]">
                                                                                <div className='leading-[14px] '>{conpasswordValidationError}</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Modal>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            </div >

            {/* Mobile */}
            <div className='grid lg:hidden grid-col-12 relative  top-[56px] bg-[#fff]'>
                <div className='col-span-12 mt-[22.5px] p-[.15rem]'>
                   
                    <div className='px-[6px] py-[5px] font-bold text-[0.875rem] !rounded-t-[3px] h-[35px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff]'>
                        <span className='relative top-[2px] font-bold text-[0.875rem] text-[#ffffff]'>Account Details</span>
                    </div>
                </div>
                <div className='col-span-12 px-[13px] py-[5px] flex-auto'>
                    <dl className='mt-0 mb-4 flex flex-wrap mr-[calc(-.5*1.5rem)] ml-[calc(-.5*1.5rem)]'>
                        <dt className='pl-[17px] pb-[5px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[25%] font-bold'>Name</dt>
                        <dd className='pl-[17px] pb-[5px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[75%]'>{userInfo?.user_name}</dd>
                        <dt className='pl-[17px] pb-[5px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[25%] font-bold'>Commission</dt>
                        <dd className='pl-[17px] pb-[5px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[75%]'> {userInfo?.commission} </dd>
                        <dt className='pl-[17px] pb-[5px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[25%] font-bold'>Rolling Commission</dt>
                        <dd className='pl-[17px] pb-[5px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[75%]'>
                            <button className='fill-[balck] hover:fill-[#09437f]' onClick={() => setOpenRollingCommission(true)}>
                                <svg width="16" height="12" viewBox="0 0 16 10" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 0C1.3 0 0 5.1 0 5.1C0 5.1 2.2 9.2 7.9 9.2C13.6 9.2 16 5.2 16 5.2C16 5.2 14.7 0 8 0ZM5.3 1.5C5.8 1.2 6.6 1.2 6.6 1.2C6.6 1.2 6.1 2.1 6.1 2.8C6.1 3.5 6.3 3.9 6.3 3.9L5.2 4.1C5.2 4.1 4.9 3.6 4.9 2.9C4.9 2.1 5.3 1.5 5.3 1.5ZM7.9 8.2C3.8 8.2 1.7 5.9 1.1 5C1.4 4.3 2.2 2.8 4.2 1.8C4.1 2.2 4 2.6 4 3.1C4 5.3 5.8 7.1 8 7.1C10.2 7.1 12 5.3 12 3.1C12 2.6 11.9 2.2 11.8 1.8C13.8 2.7 14.6 4.3 14.9 5C14.2 5.9 12.1 8.2 7.9 8.2Z" />
                                </svg>
                            </button>
                            <Modal title={'Rolling Commission'} className='rollingcommission-model relative top-1 lg:top-7 lg:!w-[34vw]' onCancel={() => setOpenRollingCommission(false)} footer={null} open={openRollingCommission} >
                                <RollingCommission />
                            </Modal>
                        </dd>
                        <dt className='pl-[17px] pb-[5px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[25%] font-bold'>Exposure Limit</dt>
                        <dd className='pl-[17px] pb-[5px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[75%]'> {userInfo?.exposer_limit} </dd>
                        <dt className='pl-[17px] pb-[5px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[25%] font-bold'>Password</dt>
                        <dd className='pl-[17px] pb-[5px] pr-[calc(1.5rem*.5)] mb-[5px] border-b-[1px] border-b-[#ccc] text-[0.875rem] flex-[0_0_auto] w-[100%] lg:w-[75%]'>
                            <span className='mr-4'>*********</span>
                            {/* <button className='!text-[#000]'>
                                <span className='flex items-center gap-1 text-[black] hover:text-[#09437f]'>Edit <TfiPencilAlt /></span>
                            </button> */}
                            <button className='!text-[#000]' onClick={() => setOpenEdit(true)}>
                                <span className='flex items-center gap-1 text-[black] hover:text-[#09437f]'>Edit <TfiPencilAlt /></span>
                            </button>
                            <Modal className='password-model relative top-1 lg:top-7 lg:!w-[34vw]'
                                footer={[
                                    <div className='col-span-12 gap-2 flex flex-shrink-0 flex-wrap items-center justify-end p-[1rem] border-t-[1px] border-t-[#dee2e6]'>
                                        <button type='submit ' disabled={button} className='text-white bg-[#1c272d] hover:!bg-transparent hover:text-inherit border-[1px] border-[#1c272d]   text-[.875rem] rounded-[.25rem] px-[10px] py-[5px]   '
                                            onClick={() => changePassword()}
                                        >
                                            Confirm
                                        </button>
                                        <button className='text-[#23282c] bg-[#c8ced3] hover:!bg-transparent hover:text-inherit border-[#c8ced3] text-[.875rem] rounded-[.25rem] inline-block px-[10px] py-[5px] font-normal leading-normal text-center no-underline align-middle border-[1px] border-[solid] border-[#6c757d] [transition:color_.15s_ease-in-out,_background-color_.15s_ease-in-out,_border-color_.15s_ease-in-out,_box-shadow_.15s_ease-in-out]'
                                            onClick={() => setOpenEdit(false)}
                                        >
                                            No
                                        </button>
                                    </div>
                                ]}
                                open={openEdit} >
                                <div className='grid grid-cols-12'>
                                    <div className='col-span-12 px-[6px] py-[5px] font-bold text-[0.875rem] rounded-t-[5px] h-[35px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex flex-shrink-0 items-center justify-between'>
                                        <h5 className='text-[0.938rem] font-bold mb-0 leading-normal'>Change Password</h5>
                                        <button type="button" onClick={() => setOpenEdit(false)} >
                                            <span className='text-[1.3125rem] font-bold'>×</span>
                                        </button>
                                    </div>
                                    <div className='col-span-12 !bg-[#ffffff] !text-[#23282c] relative flex-auto p-4'>
                                        <div className='grid grid-cols-12 lg:gap-x-6 '>
                                            <div className='col-span-12 lg:col-span-6'>
                                                <div className='mb-4 !text-[#23282c]'>
                                                    <label className='inline-block mb-[.5rem] !text-[#23282c]' for="oldPassword">Old Password </label>
                                                    <div className='relative flex flex-wrap items-stretch w-full text-[#23282c]'>
                                                        <Input.Password id='oldPassword' name='oldPassword' required onChange={(e) => setOldPassword(e.target.value)} className='antd-password' placeholder="Old Password.." />
                                                        <span className="absolute top-[25px] oldpassword-error hidden  text-[0.813rem] text-[red] leading-[14px]">
                                                            <div className='leading-[14px] '>{user_nameValidationError}</div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-span-12 lg:col-span-6'>
                                                <div className='mb-4 !text-[#23282c]'>
                                                    <label className='inline-block mb-[.5rem] !text-[#23282c]' for="newPassword">New Password  </label>
                                                    <div className='relative flex flex-wrap items-stretch w-full text-[#23282c]'>
                                                        <Input.Password id='newPassword' name='newPassword' required className='antd-password' onChange={(e) => setNewPassword(e.target.value)} pattern='^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&]{8,}$' placeholder="New Password.." />
                                                        <span className="absolute top-[25px] newpassword-error hidden  text-[0.813rem] text-[red] leading-[14px]">
                                                            <div className='leading-[14px] '>{passwordValidationError}</div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-span-12 lg:col-span-6'>
                                                <div className='mb-4 !text-[#23282c]'>
                                                    <label className='inline-block mb-[.5rem] !text-[#23282c]' for="confirmPass">Confirm Password  </label>
                                                    <div className='relative flex flex-wrap items-stretch w-full text-[#23282c]'>
                                                        <Input.Password id='confirmPass' name='confirmPass' required className='antd-password' onChange={(e) => setConPassword(e.target.value)} placeholder="Confirm Password.." />
                                                        <span className="absolute top-[25px] conpassword-error hidden  text-[0.813rem] text-[red] leading-[14px]">
                                                            <div className='leading-[14px] '>{conpasswordValidationError}</div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Modal>
                        </dd>
                    </dl>
                </div>
            </div>
        </>
    )

}
export default MyProfile